import flavours from './flavours';

const applicationBasedStyles = flavours[import.meta.env.VITE_APPLICATION_NAME];

const getOverriddenStyles = (defaultTheme) => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: defaultTheme.palette.grey[300],
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTab-root.Mui-selected': {
          fontWeight: 700,
        },
        '& .MuiTab-root:not(.Mui-selected)': {
          color: defaultTheme.palette.grey[800],
          '&:hover': {
            color: defaultTheme.palette.primary.main,
          },
        },
      },
      indicator: {
        height: 4,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        backgroundColor: defaultTheme.palette.common.white,
        textTransform: 'none',
        minWidth: '120px',
        '&:hover': {
          color: defaultTheme.palette.primary.main,
        },
      },
      wrapper: {
        flexDirection: 'row',
        '& .MuiSvgIcon-root': {
          marginBottom: '0 !important',
          marginRight: defaultTheme.spacing(2),
        },
      },
      labelIcon: {
        minHeight: 'none',
      },
    },
  },
  MuiPickersTimePickerToolbar: {
    styleOverrides: {
      hourMinuteLabel: {
        alignItems: 'center',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      dayDisabled: {
        color: `${defaultTheme.palette.grey[400]} !important`,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      popper: {
        zIndex: 1302,
      },
      arrow: {
        color: defaultTheme.palette.grey[800],
      },
      tooltip: {
        borderRadius: 4,
        backgroundColor: defaultTheme.palette.grey[800],
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: `${defaultTheme.palette.grey[100]} !important`,
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      markLabel: {
        top: 22,
      },
      mark: {
        backgroundColor: 'transparent',
      },
      markActive: {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        border: `1px solid ${defaultTheme.palette.secondary.light}`,
      },
    },
  },
  MuiListItemText: {
    variants: [
      {
        props: { variant: 'sub-item-text' },
        style: {
          '& > .MuiTypography-root': {
            fontSize: '14px',
          },
        },
      },
    ],
  },
  MuiListItem: {
    variants: [
      {
        props: { variant: 'parent-item' },
        style: {
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
            borderLeft: 'none',
            paddingLeft: defaultTheme.spacing(2.5),
            color: defaultTheme.palette.grey[800],
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        paddingTop: defaultTheme.spacing(1.4),
        paddingBottom: defaultTheme.spacing(1.4),
        borderLeft: '5px solid transparent',
        '&.Mui-selected': {
          backgroundColor: defaultTheme.palette.primary.light1,
          color: defaultTheme.palette.primary.main,
          borderColor: defaultTheme.palette.primary.main,
          '& .MuiListItemIcon-root': {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.common.white,
          },
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.light1,
          },
        },
        '&::marker': {
          lineHeight: '16px',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: defaultTheme.palette.primary.light1,
          color: defaultTheme.palette.primary.main,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 38,
        minHeight: 38,
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: defaultTheme.spacing(2),
        borderRadius: '50%',
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        '&.Mui-active': {
          '&.MuiStepLabel-label': {
            color: 'inherit',
          },
        },
        '&.Mui-completed': {
          '&.MuiStepLabel-label': {
            color: 'inherit',
          },
        },
      },
      label: {
        color: 'inherit',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
        flex: '1 1 100%',
        padding: defaultTheme.spacing(1),
        [defaultTheme.breakpoints.up('sm')]: {
          padding: defaultTheme.spacing(2),
        },
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        alignItems: 'stretch',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: `1px solid ${defaultTheme.palette.grey[300]}`,
        paddingBottom: 20,
      },
      title: {
        order: 1,
        color: defaultTheme.palette.common.black,
        fontWeight: 700,
        wordBreak: 'break-word',
      },
      subheader: {
        order: 0,
        wordBreak: 'break-word',
      },
      action: {
        alignSelf: 'auto',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${defaultTheme.palette.grey[300]}`,
        paddingBottom: 20,
        margin: 0,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
      select: {
        borderRadius: 6,
        '&:focus': {
          borderRadius: 6,
        },
      },
      filled: {
        width: 'calc(100% - 40px)',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          background: defaultTheme.palette.secondary.light1,
          padding: defaultTheme.spacing(0.25, 0),
          '& .MuiInputBase-input': {
            '&:not(:placeholder-shown)': {
              color: defaultTheme.palette.grey.black,
            },
            '&::placeholder': {
              color: `${defaultTheme.palette.grey[600]} !important`,
            },
          },
        },
      },
      popupIndicator: {
        color: defaultTheme.palette.grey[400],
      },
      clearIndicator: {
        color: defaultTheme.palette.grey[400],
      },
      popper: {
        borderRadius: 6,
        marginTop: defaultTheme.spacing(0),
      },
      listbox: {
        borderRadius: 6,
        maxHeight: '300px',
      },
      tag: {
        fontSize: '0.75rem',
        height: 26,
        lineHeight: '26px',
        borderRadius: 6,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        height: 16,
        lineHeight: '16px',
      },
      outlinedPrimary: {
        background: `${defaultTheme.palette.primary.main}1a`,
        color: defaultTheme.palette.grey[800],
      },
      outlinedError: {
        background: `${defaultTheme.palette.error.main}1a`,
        color: defaultTheme.palette.grey[800],
      },
      outlinedSecondary: {
        borderColor: defaultTheme.palette.success.main,
        background: `${defaultTheme.palette.success.main}1a`,
        color: defaultTheme.palette.grey[800],
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 6,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: 6,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: defaultTheme.spacing(2),
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        padding: defaultTheme.spacing(2),
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: defaultTheme.spacing(3),
        paddingLeft: defaultTheme.spacing(4),
        paddingBottom: 0,
        color: defaultTheme.palette.grey[700],
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 20,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: 6,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',

        '&[disabled]': {
          color: 'grey',
          cursor: 'default',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 5,
        boxShadow: '20px 32px 44px rgba(0, 0, 0, 0.14)',
        borderRadius: 3,
        background: `linear-gradient(45deg, ${defaultTheme.palette.success.main} 30%, ${defaultTheme.palette.success.main} 90%)`,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        border: 'none',
        boxShadow: 'none',
        '&:before': {
          height: 0,
        },
        margin: 0,
        padding: defaultTheme.spacing(0, 1),
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        margin: 0,
        padding: 0,
        height: '48px',
        display: 'inline-flex',
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        flexDirection: 'column',
        margin: 0,
        padding: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
        paddingLeft: 0,
        paddingRight: defaultTheme.spacing(2),
        borderColor: `${defaultTheme.palette.grey[200]} !important`,
      },
      head: {
        borderBottomWidth: 2,
      },
      stickyHeader: {
        backgroundColor: defaultTheme.palette.common.white,
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'btn-group-active' },
        style: {
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.light1,
          },
          zIndex: 1,
          textTransform: 'none',
          minHeight: 40,
          minWidth: '140px !important',
          border: `1px solid ${defaultTheme.palette.primary.main} !important`,
          backgroundColor: defaultTheme.palette.primary.light1,
          color: defaultTheme.palette.primary.main,
        },
      },
      {
        props: { variant: 'btn-group-inactive' },
        style: {
          minHeight: 40,
          '&:hover': {
            backgroundColor: defaultTheme.palette.grey.white,
            color: defaultTheme.palette.primary.main,
          },
          textTransform: 'none',
          minWidth: '140px !important',
          border: `1px solid ${defaultTheme.palette.grey[300]}`,
          color: defaultTheme.palette.grey[800],
        },
      },
    ],
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: 12,
        lineHeight: 1.5,
        borderRadius: 4,
        padding: defaultTheme.spacing(1, 2),
        color: defaultTheme.palette.grey[700],
      },
      text: {
        padding: defaultTheme.spacing(1),
      },
      label: {
        whiteSpace: 'inherit',
      },
      containedInherit: {
        backgroundColor: defaultTheme.palette.secondary.main,
        color: defaultTheme.palette.grey[800],
        '&:hover': {
          backgroundColor: defaultTheme.palette.secondary.dark1,
        },
      },
      containedPrimary: {
        color: defaultTheme.palette.common.white,
        backgroundColor: defaultTheme.palette.primary.main,
        '&:hover': {
          backgroundColor: defaultTheme.palette.primary.dark,
        },
      },
      containedSecondary: {
        color: defaultTheme.palette.common.white,
        backgroundColor: defaultTheme.palette.success.main,
        '&:hover': {
          backgroundColor: defaultTheme.palette.success.dark,
        },
      },
      containedError: {
        color: defaultTheme.palette.common.white,
        backgroundColor: defaultTheme.palette.error.main,
        '&:hover': {
          backgroundColor: defaultTheme.palette.error.dark,
        },
      },
      contained: {
        boxShadow: 'none',
        padding: defaultTheme.spacing(1, 2),
        color: defaultTheme.palette.grey[700],
        whiteSpace: 'nowrap',
      },
      outlined: {
        boxShadow: 'none',
        padding: defaultTheme.spacing(1, 2),
        whiteSpace: 'nowrap',
        borderColor: defaultTheme.palette.primary.main,
      },
      outlinedPrimary: {
        color: defaultTheme.palette.primary.main,
        '&:hover': {
          color: defaultTheme.palette.grey.black,
          backgroundColor: defaultTheme.palette.secondary.main,
        },
      },
      textPrimary: {
        color: defaultTheme.palette.primary.main,
      },
      textSecondary: {
        color: defaultTheme.palette.success.main,
      },
      textGrey: {
        color: defaultTheme.palette.grey[600],
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: defaultTheme.spacing(1),
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: 'initial',
      },
      input: {
        '&::placeholder': {
          color: 'defaultTheme.palette.grey[500]',
          opacity: 1,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: defaultTheme.palette.secondary.light,
      },
      notchedOutline: {
        border: 'none',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      spacer: {
        [defaultTheme.breakpoints.down('xl')]: {
          display: 'none',
        },
      },
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      toolbar: {
        minHeight: 40,
        padding: '0 !important',
      },
      selectRoot: {
        [defaultTheme.breakpoints.down('lg')]: {
          marginRight: defaultTheme.spacing(1),
        },
      },
      actions: {
        [defaultTheme.breakpoints.down('lg')]: {
          marginLeft: defaultTheme.spacing(1),
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      colorTextPrimary: {
        color: defaultTheme.palette.grey.black,
      },
    },
    variants: [
      {
        props: { color: 'errorContrastTextLight' },
        style: {
          color: defaultTheme.palette.error.dark,
        },
      },
    ],
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        borderRadius: 4,
      },
      message: {
        fontSize: 16,
      },
      standardInfo: {
        backgroundColor: defaultTheme.palette.primary.light1,
        color: defaultTheme.palette.secondary.dark,
        '& .MuiAlert-icon': {
          color: defaultTheme.palette.primary.main,
        },
        '& .MuiAlert-message': {
          color: defaultTheme.palette.primary.dark,
        },
      },
      standardWarning: {
        backgroundColor: defaultTheme.palette.warning.light1,
        color: defaultTheme.palette.warning.dark,
        '& .MuiAlert-icon': {
          color: defaultTheme.palette.warning.main,
        },
        '& .MuiAlert-message': {
          color: defaultTheme.palette.warning.dark,
        },
      },
      standardSuccess: {
        backgroundColor: defaultTheme.palette.success.light1,
        color: defaultTheme.palette.success.dark,
        '& .MuiAlert-icon': {
          color: defaultTheme.palette.success.main,
        },
        '& .MuiAlert-message': {
          color: defaultTheme.palette.success.dark,
        },
      },
      standardError: {
        backgroundColor: defaultTheme.palette.error.light1,
        color: defaultTheme.palette.error.dark,
        '& .MuiAlert-icon': {
          color: defaultTheme.palette.error.main,
        },
        '& .MuiAlert-message': {
          color: defaultTheme.palette.error.dark,
        },
      },
    },
  },
  MuiClockPicker: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          color: 'inherit',
        },
      },
    },
  },
  MuiClock: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          color: defaultTheme.palette.grey.black,
        },
        '& .MuiButtonBase-root.MuiIconButton-root:hover': {
          backgroundColor: defaultTheme.palette.primary.light2,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        '&.Mui-focused': {
          color: defaultTheme.palette.grey[800],
        },
      },
    },
  },
  ...applicationBasedStyles,
});

export default getOverriddenStyles;
