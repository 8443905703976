import { Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import * as PropTypes from 'prop-types';

import WrapLink from '@uptime/shared/components/WrapLink';
import { responseLens } from '@uptime/shared/utils/general';
import { getRunTaskFilterForBadges } from '@uptime/shared/utils/checkList';
import { getInProgressTasksCounts } from '@uptime/shared/graphql/checkList';

import { getFacilityOptionsForUser } from 'graphql/facility';
import AuthContext from 'context/AuthContext';
import { facilityClient, goCheckItClient } from 'apolloClientConfiguration';
import { UIContext } from 'providers/UIProvider';
import usePermissions from 'shared/hooks/usePermissions';
import Badge from 'libs/components/Badge';

import useLogsCount from '../hooks/useLogsCount';
import useWOCount from '../hooks/useWOCount';
import useDocumentsCount from '../hooks/useDocumentsCount';
import { useStyles } from '../LeftNav.styles';
import BasicMenuItems from './BasicMenuItems';
import DashboardMenu from './DashboardMenu';
import RepairMenuItems from './RepairMenuItems';

const MenuItems = (props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { isTopLevelFacility, isRepairer, makeUrl, onClickItem, hideBusinessTabs } = props;

  const { userId, accountId, isTopLevel, isStaff } = useContext(AuthContext);
  const { isOpenSubMenu, isOpenSubMenu2Level, onToggle, onSubMenuToggle, onClickMenuItem } =
    useContext(UIContext);

  const {
    runTasksPermission,
    logsManagementPermission,
    hasAnyDocumentsPermission,
    techTeamManagementPermission,
    commonPermission,
  } = usePermissions();

  const { woCount } = useWOCount({
    accountId,
    isRepairer,
    skip: !isRepairer && !commonPermission,
  });

  const { logsCount } = useLogsCount({
    accountId,
    isRepairer,
    logsManagementPermission,
  });

  const { data } = useQuery(getFacilityOptionsForUser, {
    variables: {
      // TODO:H:3
      userId: accountId,
    },
    fetchPolicy: 'cache-and-network',
    client: facilityClient,
    skip: !accountId || (!isRepairer && !commonPermission),
  });
  const facilities = get(data, 'facilities.hits', []);
  const facilityIds = facilities?.map((facility) => facility.value);
  const variables = isTopLevel ? undefined : { facilityIds };

  const { documentsCount } = useDocumentsCount({
    skip: !userId || isRepairer || !hasAnyDocumentsPermission,
    variables,
  });

  const { data: runTasksCount } = useQuery(getInProgressTasksCounts, {
    variables: {
      userId,
      filter: getRunTaskFilterForBadges(),
    },
    skip: !userId || isRepairer || !runTasksPermission,
    client: goCheckItClient,
    fetchPolicy: 'network-only',
  });
  const inProgressRunTaskCount = responseLens(runTasksCount, 'inProgress.itemsCount', 0);
  const overdueRunTaskCount = responseLens(runTasksCount, 'overdue.itemsCount', 0);
  const activeRunTaskCount = inProgressRunTaskCount + overdueRunTaskCount;

  const menuItems = isRepairer
    ? RepairMenuItems({ woCount, subMenuUrl: isOpenSubMenu })
    : BasicMenuItems({
        activeRunTaskCount,
        woCount,
        subMenuUrl: isOpenSubMenu,
        isTopLevelFacility,
        isStaff,
        hideBusinessTabs,
        documentsCount,
        logsCount,
      });

  const mappedMenuItems = menuItems.map((item) => {
    if (!item.permission) {
      return;
    }

    const shouldHideTechnicianTeam = !techTeamManagementPermission && item.url === 'technician/my-team';

    if (shouldHideTechnicianTeam) {
      return null;
    }

    const marketplacePaths = ['/app/marketplace', '/app/marketplace/search'];
    const technicianPaths = [
      '/app/edit-technician/service',
      '/app/edit-technician/coverage',
      '/app/edit-technician/equipment',
    ];

    const onMarketRoute =
      (marketplacePaths.includes(pathname) || pathname.includes('/app/marketplace')) &&
      item.url === 'service-providers';
    const onTechnicianRoute = technicianPaths.includes(pathname) && item.url === 'technician/marketplace';
    const onTechnicianMarketRoute =
      (marketplacePaths.includes(pathname) || pathname.includes('/app/marketplace')) &&
      item.url === 'technician/my-team';
    const onDocumentsRoute = pathname.startsWith('/app/documents/') && item.url.startsWith('documents/');
    const hasChildren = item.children?.length;

    return !hasChildren ? (
      <Fragment key={item.url}>
        <ListItem
          component={WrapLink}
          to={makeUrl(item.url)}
          button
          {...(onDocumentsRoute && { isActive: () => onDocumentsRoute })}
          {...(onMarketRoute && { isActive: () => onMarketRoute })}
          {...(onTechnicianRoute && { isActive: () => onTechnicianRoute })}
          {...(onTechnicianMarketRoute && { isActive: () => onTechnicianMarketRoute })}
          activeClassName="Mui-selected"
          onClick={() => {
            onClickMenuItem(item.url);
            return onClickItem();
          }}
          data-testid="leftMenuItem"
        >
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.title} />
          {item.badge !== undefined && (
            <Box mr="30px">
              <Badge badgeContent={item.badge} />
            </Box>
          )}
        </ListItem>
      </Fragment>
    ) : (
      <Fragment key={item.url}>
        <ListItem
          component={WrapLink}
          to={makeUrl(item.url)}
          button
          onClick={onToggle(item.url)}
          activeClassName="Mui-selected"
          isActive={item.isActive}
          variant="parent-item"
          data-testid="leftMenuItem"
        >
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.title} />
          {item.badge !== undefined && (
            <Box mr="6px">
              <Badge badgeContent={item.badge} />
            </Box>
          )}
          {isOpenSubMenu === item.url ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItem>
        <Collapse in={isOpenSubMenu === item.url} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((subItem) => {
              if (!subItem) {
                return null;
              }

              if (!subItem.permission) {
                return null;
              }

              return (
                <Fragment key={subItem.url}>
                  <ListItem
                    component={WrapLink}
                    to={makeUrl(subItem.url)}
                    button
                    className={classes.nested}
                    activeClassName="Mui-selected"
                    isActive={subItem.isActive}
                    onClick={subItem.children ? onSubMenuToggle(item.url) : onClickItem}
                    data-testid="leftMenuItem"
                  >
                    {subItem.icon && <ListItemIcon>{subItem.icon}</ListItemIcon>}
                    <ListItemText variant="sub-item-text" primary={subItem.title} />
                    {subItem.badge !== undefined && (
                      <Box mr="30px">
                        <Badge badgeContent={subItem.badge} />
                      </Box>
                    )}
                    {subItem.children &&
                      (isOpenSubMenu2Level === item.url ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      ))}
                  </ListItem>
                  {subItem.children && (
                    <Collapse in={isOpenSubMenu2Level === item.url} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {subItem.children.map((subItem2Level) => (
                          <ListItem
                            component={WrapLink}
                            to={makeUrl(subItem2Level.url)}
                            key={subItem2Level.url}
                            button
                            className={classes.nested2Level}
                            activeClassName="Mui-selected"
                            onClick={onClickItem}
                            data-testid="leftMenuItem"
                          >
                            {subItem2Level.icon && <ListItemIcon>{subItem2Level.icon}</ListItemIcon>}
                            <ListItemText variant="sub-item-text" primary={subItem2Level.title} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Fragment>
              );
            })}
          </List>
        </Collapse>
      </Fragment>
    );
  });

  if (!isRepairer) {
    mappedMenuItems.splice(1, 0, <DashboardMenu clicked={onClickItem} makeUrl={makeUrl} key={99} />);
  }

  return mappedMenuItems;
};

MenuItems.propTypes = {
  isTopLevelFacility: PropTypes.bool,
  isRepairer: PropTypes.bool,
  makeUrl: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  hideBusinessTabs: PropTypes.bool,
};

export default MenuItems;
