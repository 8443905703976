import styled from 'styled-components';
import { MaterialDesignContent } from 'notistack';

import muiTheme from './muiTheme';

const ThemedSnackbar = styled(MaterialDesignContent)(() => ({
  boxShadow: '0px 0.5px 2px rgba(0, 0, 0, 0.04), 0px 1px 4px rgba(0, 0, 0, 0.2)',
  '&.notistack-MuiContent-success': {
    backgroundColor: muiTheme.palette.success.light1,
    color: muiTheme.palette.success.dark,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: muiTheme.palette.error.light1,
    color: muiTheme.palette.error.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: muiTheme.palette.warning.light1,
    color: muiTheme.palette.warning.dark,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: muiTheme.palette.primary.light1,
    color: muiTheme.palette.primary.dark,
  },
}));

export default ThemedSnackbar;
