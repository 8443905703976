import { gql } from '@apollo/client';

const COLUMN_INFO = `
  fragment columnInfo on ListSettingProperty {
    columnId,
    isActive,
    position,
  }
`;

const SETTINGS = `
  photo {
    ...columnInfo,
  },
  title {
    ...columnInfo,
  },
  addressOne {
    ...columnInfo,
  },
  schedule {
    ...columnInfo,
  },
  areaCount {
    ...columnInfo,
  },
  deviceCount {
    ...columnInfo,
  },
  taskCount {
    ...columnInfo,
  },
  logCount {
    ...columnInfo,
  },
  jobCount {
    ...columnInfo,
  },
`;

// queries
// TODO:H:3 - PROD-6891
export const getFacility = gql`
  query getFacility($userId: Int!, $facilityId: Int!, $joinNonWorkingDays: Boolean) {
    facility: getFacility(userId: $userId, facilityId: $facilityId, joinNonWorkingDays: $joinNonWorkingDays) {
      id
      title
      description
      contactId
      fileId
      postal
      addressOne
      addressTwo
      state
      phoneNumber
      officeTypeId
      officeType {
        id
        alias
        title
      }
      coordinates {
        x
        y
      }
      schedule {
        workingDays
      }
      nonWorkingDays {
        id
        unixStart
        unixEnd
        usaDate
        subject
      }
      areas {
        id
        typeId
        type {
          title
          id
        }
        title
        description
        deviceCount
        taskCount
      }
      coordinates {
        latitude: y
        longitude: x
      }
      isBillAddress
    }
  }
`;

// TODO:H:8 - PROD-6891 ???
export const getArea = gql`
  query getArea($userId: Int!, $areaId: Int!) {
    area: getArea(userId: $userId, areaId: $areaId) {
      facilityId
      title
      description
      type {
        typeTitle: title
      }
      facility {
        title
      }
    }
  }
`;

export const getAreaTypes = gql`
  query getAreaTypes {
    getAreaTypes {
      value: id
      label: title
    }
  }
`;

export const isFacilityTitleUnique = gql`
  query isFacilityTitleUnique($userId: Int!, $title: String!, $facilityId: Int) {
    isFacilityTitleUnique(userId: $userId, title: $title, facilityId: $facilityId) {
      isUnique
    }
  }
`;

export const isFacilityTitleUniqueAsTechnician = gql`
  query isFacilityTitleUniqueAsTechnician(
    $facilityUserId: Int!
    $title: String!
    $facilityId: Int
    $signature: String!
  ) {
    isFacilityTitleUniqueAsTechnician(
      facilityUserId: $facilityUserId
      title: $title
      facilityId: $facilityId
      signature: $signature
    ) {
      isUnique
    }
  }
`;

export const isAreaTitleUnique = gql`
  query isAreaTitleUnique($userId: Int!, $title: String!, $facilityId: Int!, $areaId: Int) {
    isAreaTitleUnique(userId: $userId, title: $title, facilityId: $facilityId, areaId: $areaId) {
      isUnique
    }
  }
`;

export const getSettings = gql`
  ${COLUMN_INFO}
  query getSettings($userId: Int!) {
      settings: getFacilityListSetting(userId: $userId) {
          ${SETTINGS}
      }
  }
`;

export const getFacilities = gql`
  query getFacilities(
    $userId: Int!
    $page: Int!
    $sort: [FacilitySortInput!] = [{ field: title, order: ASC }]
    $search: String
    $polygon: PolygonInput
    $internalPolygon: PolygonInput
    $itemsAmount: Int = 10
  ) {
    facilities: facilityList(
      userId: $userId
      sortBy: $sort
      searchBy: $search
      pagination: { page: $page, itemsAmount: $itemsAmount }
      polygon: $polygon
      internalPolygon: $internalPolygon
    ) {
      hits {
        id
        fileId
        title
        addressOne
        schedule {
          workingDays
        }
        areaCount
        deviceCount
        jobCount
        logCount
        taskCount
        mapFileId
        coordinates {
          latitude: y
          longitude: x
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

// TODO:H:3
export const getFacilityLocationOptions = gql`
  query getFacilities($userId: Int!) {
    facilities: facilityList(
      userId: $userId
      sortBy: [{ field: title, order: ASC }]
      pagination: { page: 1, itemsAmount: 1000 }
    ) {
      options: hits {
        label: title
        value: coordinates {
          y
          x
        }
      }
    }
  }
`;

// TODO:H:3
export const getFacilityOptionsForUser = gql`
  query facilityList($userId: Int!) {
    facilities: facilityList(
      userId: $userId
      pagination: { page: 1, itemsAmount: 1000 }
      sortBy: { field: title, order: ASC }
    ) {
      hits {
        value: id
        label: title
        addressOne
        addressTwo
        state
        postal
      }
    }
  }
`;

export const getFacilityOptions = gql`
  query getFacilities($userId: Int!, $sort: [FacilitySortInput!] = [{ field: title, order: ASC }]) {
    facilities: getFacilities(userId: $userId, sortBy: $sort) {
      value: id
      label: title
    }
  }
`;

export const getFacilityOptionsAsTechnician = gql`
  query technicianFacilityList($filter: FacilityFilterInput) {
    facilities: technicianFacilityList(filter: $filter) {
      hits {
        value: id
        label: title
      }
    }
  }
`;

export const getFacilityAreaOptions = gql`
  query getFacilityAreas($facilityId: Int!, $typeId: Int) {
    areas: getFacilityAreas(facilityId: $facilityId, typeId: $typeId) {
      value: id
      label: title
    }
  }
`;

export const getAreasByIds = gql`
  query getAreas($areaIds: [Int!]!) {
    getAreas(areaIds: $areaIds) {
      id
      title
      description
      type {
        id
        title
      }
      facilityId
      facility {
        userId
      }
    }
  }
`;

// mutations
export const onFacilityCreate = gql`
  mutation createFacility(
    $userId: Int!
    $facilityData: FacilityCreationInput!
    $areaData: [AreaCreationInput]!
    $nonWorkingDays: UpdateNwdInput
  ) {
    createFacility(
      userId: $userId
      facilityData: $facilityData
      areaData: $areaData
      nonWorkingDays: $nonWorkingDays
    ) {
      id
      contactId
      title
      description
      addressOne
      addressTwo
      state
      postal
      deviceCount
      areaCount
    }
  }
`;

export const onCreateFacilityByTechnician = gql`
  mutation createFacilityByTechnician(
    $facilityUserId: Int!
    $facilityData: FacilityCreationInput!
    $areaData: [AreaCreationInput]!
    $signature: String
  ) {
    createFacilityByTechnician(
      facilityUserId: $facilityUserId
      facilityData: $facilityData
      areaData: $areaData
      signature: $signature
    ) {
      id
      contactId
      title
      description
      addressOne
      addressTwo
      state
      postal
      deviceCount
      areaCount
    }
  }
`;

export const onUpdateSettings = gql`
  ${COLUMN_INFO}
  mutation updateFacilityListSetting($userId: Int!, $settings: FacilityListSettingInput){
      updateFacilityListSetting(userId: $userId, settings: $settings) {
          ${SETTINGS}
      }
  }
`;

export const onFacilityUpdate = gql`
  mutation updateFacility(
    $userId: Int!
    $facilityData: FacilityUpdatingInput!
    $nonWorkingDays: UpdateNwdInput
  ) {
    updateFacility(userId: $userId, facilityData: $facilityData, nonWorkingDays: $nonWorkingDays) {
      id
      title
      description
      addressOne
      addressTwo
      state
      postal
      deviceCount
      areaCount
      phoneNumber
    }
  }
`;

export const onDeleteArea = gql`
  mutation deleteArea($userId: Int!, $facilityId: Int!, $areaIds: [Int]!) {
    deleteArea(userId: $userId, facilityId: $facilityId, areaIds: $areaIds)
  }
`;

export const onUpdateArea = gql`
  mutation updateArea($userId: Int!, $facilityId: Int!, $areaData: [AreaUpdatingInput]!) {
    updateArea(userId: $userId, facilityId: $facilityId, areaData: $areaData) {
      id
      facilityId
    }
  }
`;

export const onCreateArea = gql`
  mutation createArea($userId: Int!, $facilityId: Int!, $areaData: [AreaCreationInput]!) {
    createArea(userId: $userId, facilityId: $facilityId, areaData: $areaData) {
      id
      facilityId
    }
  }
`;

export const deleteFacility = gql`
  mutation deleteFacility($userId: Int!, $facilityId: Int!) {
    deleteFacility(userId: $userId, facilityId: $facilityId)
  }
`;

export const getFacilityContracts = gql`
  query getFacilityContracts($facilityId: Int!) {
    facilityContracts(facilityId: $facilityId) {
      id
      serviceProviderId
      facilityId
      number
      typeId
      start
      end
      paymentMethod
      value
      type {
        id
        title
      }
    }
  }
`;

export const getFacilityForTechnician = gql`
  query getFacilityForTechnician($facilityId: Int!) {
    facility: facilityForTechnician(facilityId: $facilityId) {
      id
      contactId
      userId
      facilityId
      title
      description
      addressOne
      addressTwo
      state
      postal
      phoneNumber
      deviceCount
      areaCount
      taskCount
      officeTypeId
      officeType {
        id
        alias
        title
      }
      schedule {
        workingDays
      }
      coordinates {
        latitude: y
        longitude: x
      }
      fileId
      mapFileId
      createdAt
      updatedAt
      isBillAddress
    }
  }
`;

export const getFacilityTimeZoneForTechnician = gql`
  query getFacilityForTechnician($facilityId: Int!) {
    facilityForTechnician(facilityId: $facilityId) {
      timezoneId
    }
  }
`;

export const getFacilityEquipments = gql`
  query getFacilityEquipments($facilityId: Int!) {
    facilityEquipments(facilityId: $facilityId) {
      equipments {
        equipmentCategoryTitle
        rate
      }
    }
  }
`;

export const getTechnicianFacilityList = gql`
  query getTechnicianFacilityList(
    $pagination: PaginationInput
    $sortBy: [FacilitySortInput!]
    $searchBy: String
    $filter: FacilityFilterInput
  ) {
    technicianFacilityList(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        title
        userId
        addressOne
        areaCount
        schedule {
          workingDays
        }
        coordinates {
          x
          y
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getAreasByFacility = gql`
  query getFacilityAreas($facilityId: Int!) {
    getFacilityAreas(facilityId: $facilityId) {
      title
      id
    }
  }
`;

export const getOfficeTypes = gql`
  query officeTypes($userId: Int!) {
    officeTypes(userId: $userId) {
      value: id
      label: title
      alias
    }
  }
`;

export const getFacilitySchedule = gql`
  query getFacilitySchedule($facilityId: Int!) {
    getFacilitySchedule(facilityId: $facilityId) {
      id
      title
      schedule {
        workingDays
      }
      nonWorkingDays {
        id
        unixStart
        unixEnd
        usaDate
        subject
      }
    }
  }
`;

export const checkIfOutsideTasksExist = gql(`
  query checkIfOutsideTasksExist(
    $facilityId: Int!
    $deviceId: Int
    $schedule: ScheduleInput!
    $timezone: String
  ) {
    isOutside: checkIfOutsideTasksExist(
      facilityId: $facilityId
      deviceId: $deviceId 
      schedule: $schedule
      timezone: $timezone
    )
  }
`);

export const syncFacilitySchedule = gql`
  mutation syncFacilitySchedule(
    $facilityId: Int!
    $schedule: ScheduleInput
    $nonWorkingDays: [NonWorkingDay]
    $timezone: String
  ) {
    syncFacilitySchedule(
      facilityId: $facilityId
      schedule: $schedule
      nonWorkingDays: $nonWorkingDays
      timezone: $timezone
    )
  }
`;
